var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-add-wrapper"
  }, [_c('b-row', {
    staticClass: "invoice-add"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_c('div', {
    staticClass: "logo-wrapper"
  }, [_c('logo'), _c('h3', {
    staticClass: "text-primary invoice-logo"
  }, [_vm._v(" Vuexy ")])], 1), _c('b-card-text', {
    staticClass: "mb-25"
  }, [_vm._v(" Office 149, 450 South Brand Brooklyn ")]), _c('b-card-text', {
    staticClass: "mb-25"
  }, [_vm._v(" San Diego County, CA 91905, USA ")]), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" +1 (123) 456 7891, +44 (876) 543 2198 ")])], 1), _c('div', {
    staticClass: "invoice-number-date mt-md-0 mt-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-md-end mb-1"
  }, [_c('h4', {
    staticClass: "invoice-title"
  }, [_vm._v(" Invoice ")]), _c('b-input-group', {
    staticClass: "input-group-merge invoice-edit-input-group disabled"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HashIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "invoice-data-id",
      "disabled": ""
    },
    model: {
      value: _vm.invoiceData.id,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceData, "id", $$v);
      },
      expression: "invoiceData.id"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center mb-1"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(" Date: ")]), _c('flat-pickr', {
    staticClass: "form-control invoice-edit-input",
    model: {
      value: _vm.invoiceData.issuedDate,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceData, "issuedDate", $$v);
      },
      expression: "invoiceData.issuedDate"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(" Due Date: ")]), _c('flat-pickr', {
    staticClass: "form-control invoice-edit-input",
    model: {
      value: _vm.invoiceData.dueDate,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceData, "dueDate", $$v);
      },
      expression: "invoiceData.dueDate"
    }
  })], 1)])])]), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('b-row', {
    staticClass: "invoice-spacing"
  }, [_c('b-col', {
    staticClass: "mb-lg-1",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('h6', {
    staticClass: "mb-2"
  }, [_vm._v(" Invoice To: ")]), _c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.clients,
      "label": "company",
      "input-id": "invoice-data-client",
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "list-header",
      fn: function fn() {
        return [_c('li', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.sidebar-invoice-add-new-customer",
            modifiers: {
              "sidebar-invoice-add-new-customer": true
            }
          }],
          staticClass: "add-new-client-header d-flex align-items-center my-50"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "align-middle ml-25"
        }, [_vm._v("Add New Customer")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.invoiceData.client,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceData, "client", $$v);
      },
      expression: "invoiceData.client"
    }
  }), _vm.invoiceData.client ? _c('div', {
    staticClass: "mt-1"
  }, [_c('h6', {
    staticClass: "mb-25"
  }, [_vm._v(" " + _vm._s(_vm.invoiceData.client.name) + " ")]), _c('b-card-text', {
    staticClass: "mb-25"
  }, [_vm._v(" " + _vm._s(_vm.invoiceData.client.company) + " ")]), _c('b-card-text', {
    staticClass: "mb-25"
  }, [_vm._v(" " + _vm._s(_vm.invoiceData.client.address) + ", " + _vm._s(_vm.invoiceData.client.country) + " ")]), _c('b-card-text', {
    staticClass: "mb-25"
  }, [_vm._v(" " + _vm._s(_vm.invoiceData.client.contact) + " ")]), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.invoiceData.client.companyEmail) + " ")])], 1) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-xl-0 mt-2 justify-content-end d-xl-flex d-block",
    attrs: {
      "xl": "6",
      "cols": "12"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "mb-2"
  }, [_vm._v(" Payment Details: ")]), _c('table', [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v(" Total Due: ")]), _c('td', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("$12,110.55")])])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v(" Bank name: ")]), _c('td', [_vm._v("American Bank")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v(" Country: ")]), _c('td', [_vm._v("United States")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v(" IBAN: ")]), _c('td', [_vm._v("ETD95476213874685")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v(" SWIFT code: ")]), _c('td', [_vm._v("BR91905")])])])])])])], 1)], 1), _c('b-card-body', {
    staticClass: "invoice-padding form-item-section"
  }, [_c('div', {
    ref: "form",
    staticClass: "repeater-form",
    style: {
      height: _vm.trHeight
    }
  }, _vm._l(_vm.invoiceData.items, function (item, index) {
    return _c('b-row', {
      key: index,
      ref: "row",
      refInFor: true,
      staticClass: "pb-2"
    }, [_c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "d-none d-lg-flex"
    }, [_c('b-row', {
      staticClass: "flex-grow-1 px-1"
    }, [_c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_vm._v(" Item ")]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_vm._v(" Cost ")]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_vm._v(" Qty ")]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_vm._v(" Price ")])], 1), _c('div', {
      staticClass: "form-item-action-col"
    })], 1), _c('div', {
      staticClass: "d-flex border rounded"
    }, [_c('b-row', {
      staticClass: "flex-grow-1 p-2"
    }, [_c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_c('label', {
      staticClass: "d-inline d-lg-none"
    }, [_vm._v("Item")]), _c('v-select', {
      staticClass: "mb-2 item-selector-title",
      attrs: {
        "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
        "options": _vm.itemsOptions,
        "label": "itemTitle",
        "clearable": false,
        "placeholder": "Select Item"
      },
      on: {
        "input": function input(val) {
          return _vm.updateItemForm(index, val);
        }
      },
      model: {
        value: item.itemTitle,
        callback: function callback($$v) {
          _vm.$set(item, "itemTitle", $$v);
        },
        expression: "item.itemTitle"
      }
    })], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_c('label', {
      staticClass: "d-inline d-lg-none"
    }, [_vm._v("Cost")]), _c('b-form-input', {
      staticClass: "mb-2",
      attrs: {
        "type": "number"
      },
      model: {
        value: item.cost,
        callback: function callback($$v) {
          _vm.$set(item, "cost", $$v);
        },
        expression: "item.cost"
      }
    })], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_c('label', {
      staticClass: "d-inline d-lg-none"
    }, [_vm._v("Qty")]), _c('b-form-input', {
      staticClass: "mb-2",
      attrs: {
        "type": "number"
      },
      model: {
        value: item.qty,
        callback: function callback($$v) {
          _vm.$set(item, "qty", $$v);
        },
        expression: "item.qty"
      }
    })], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_c('label', {
      staticClass: "d-inline d-lg-none"
    }, [_vm._v("Price")]), _c('p', {
      staticClass: "mb-1"
    }, [_vm._v(" $" + _vm._s(item.cost * item.qty) + " ")])]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_c('label', {
      staticClass: "d-inline d-lg-none"
    }, [_vm._v("Description")]), _c('b-form-textarea', {
      staticClass: "mb-2 mb-lg-0",
      model: {
        value: item.description,
        callback: function callback($$v) {
          _vm.$set(item, "description", $$v);
        },
        expression: "item.description"
      }
    })], 1), _c('b-col', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v(" Discount: 0% 0% 0% ")])])], 1), _c('div', {
      staticClass: "d-flex flex-column justify-content-between border-left py-50 px-25"
    }, [_c('feather-icon', {
      staticClass: "cursor-pointer",
      attrs: {
        "size": "16",
        "icon": "XIcon"
      },
      on: {
        "click": function click($event) {
          return _vm.removeItem(index);
        }
      }
    }), _c('feather-icon', {
      staticClass: "cursor-pointer",
      attrs: {
        "id": "form-item-settings-icon-".concat(index),
        "size": "16",
        "icon": "SettingsIcon"
      }
    }), _c('b-popover', {
      ref: "form-item-settings-popover-".concat(index),
      refInFor: true,
      attrs: {
        "target": "form-item-settings-icon-".concat(index),
        "triggers": "click",
        "placement": "lefttop"
      }
    }, [_c('b-form', {
      on: {
        "submit": function submit($event) {
          $event.preventDefault();
        }
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Discount(%)",
        "label-for": "setting-item-".concat(index, "-discount")
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "setting-item-".concat(index, "-discount"),
        "value": null,
        "type": "number"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Tax 1",
        "label-for": "setting-item-".concat(index, "-tax-1")
      }
    }, [_c('v-select', {
      attrs: {
        "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
        "value": '10%',
        "options": ['0%', '1%', '10%', '14%', '18%'],
        "input-id": "setting-item-".concat(index, "-tax-1"),
        "clearable": false
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Tax 2",
        "label-for": "setting-item-".concat(index, "-tax-2")
      }
    }, [_c('v-select', {
      attrs: {
        "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
        "value": '10%',
        "options": ['0%', '1%', '10%', '14%', '18%'],
        "input-id": "setting-item-".concat(index, "-tax-2"),
        "clearable": false
      }
    })], 1)], 1), _c('b-col', {
      staticClass: "d-flex justify-content-between mt-1",
      attrs: {
        "cols": "12"
      }
    }, [_c('b-button', {
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click() {
          _vm.$refs["form-item-settings-popover-".concat(index)][0].$emit('close');
        }
      }
    }, [_vm._v(" Apply ")]), _c('b-button', {
      attrs: {
        "variant": "outline-secondary"
      },
      on: {
        "click": function click() {
          _vm.$refs["form-item-settings-popover-".concat(index)][0].$emit('close');
        }
      }
    }, [_vm._v(" Cancel ")])], 1)], 1)], 1)], 1)], 1)], 1)])], 1);
  }), 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.addNewItemInItemForm
    }
  }, [_vm._v(" Add Item ")])], 1), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-md-0 mt-3 d-flex align-items-center",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "2",
      "order-md": "1"
    }
  }, [_c('label', {
    staticClass: "text-nowrap mr-50",
    attrs: {
      "for": "invoice-data-sales-person"
    }
  }, [_vm._v("Sales Person:")]), _c('b-form-input', {
    attrs: {
      "id": "invoice-data-sales-person",
      "placeholder": "Edward Crowley"
    },
    model: {
      value: _vm.invoiceData.salesPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceData, "salesPerson", $$v);
      },
      expression: "invoiceData.salesPerson"
    }
  })], 1), _c('b-col', {
    staticClass: "mt-md-6 d-flex justify-content-end",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "invoice-total-wrapper"
  }, [_c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v(" Subtotal: ")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" $1800 ")])]), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v(" Discount: ")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" $28 ")])]), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v(" Tax: ")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" 21% ")])]), _c('hr', {
    staticClass: "my-50"
  }), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v(" Total: ")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" $1690 ")])])])])], 1)], 1), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note: ")]), _c('b-form-textarea', {
    model: {
      value: _vm.invoiceData.note,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceData, "note", $$v);
      },
      expression: "invoiceData.note"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions mt-md-0 mt-2",
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "primary",
      "block": "",
      "disabled": ""
    }
  }, [_vm._v(" Send Invoice ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-primary",
      "block": ""
    }
  }, [_vm._v(" Preview ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary",
      "block": ""
    }
  }, [_vm._v(" Save ")])], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Accept Payment Via",
      "label-for": "payment-method"
    }
  }, [_c('v-select', {
    staticClass: "payment-selector",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.paymentMethods,
      "input-id": "payment-method",
      "clearable": false
    },
    model: {
      value: _vm.invoiceData.paymentMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.invoiceData, "paymentMethod", $$v);
      },
      expression: "invoiceData.paymentMethod"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('label', {
    attrs: {
      "for": "patymentTerms"
    }
  }, [_vm._v("Payment Terms")]), _c('b-form-checkbox', {
    attrs: {
      "id": "patymentTerms",
      "checked": true,
      "switch": ""
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-1"
  }, [_c('label', {
    attrs: {
      "for": "clientNotes"
    }
  }, [_vm._v("Client Notes")]), _c('b-form-checkbox', {
    attrs: {
      "id": "clientNotes",
      "checked": true,
      "switch": ""
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('label', {
    attrs: {
      "for": "paymentStub"
    }
  }, [_vm._v("Payment Stub")]), _c('b-form-checkbox', {
    attrs: {
      "id": "paymentStub",
      "switch": ""
    }
  })], 1)], 1)], 1)], 1), _c('invoice-sidebar-add-new-customer')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }